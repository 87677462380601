

















import Vue from 'vue';
import gql from 'graphql-tag';

export default Vue.extend({
  name: 'sls-admin-oAuthCodeCatcher',
  data() {
    return {
      oAuthCode: '' as string | Array<(string | null)>,
      pass: null as string | null,
      res: null as null | boolean,
    };
  },
  mounted() {
    this.oAuthCode = this.$route.query.code;
  },
  methods: {
    async sendAuthCode() {
      const res = await this.$apollo.mutate({
      // Query
        mutation: gql`mutation ($code: String!, $password: String!) {
          spirespyGoogleSheetsAuthCode(code: $code, password: $password)
        }`,
        variables: {
          code: this.oAuthCode,
          password: this.pass,
        },
      });
      this.res = res.data.spirespyGoogleSheetsAuthCode;
    },
  //   copyTestingCode () {
  //     let testingCodeToCopy = document.querySelector('#testing-code');
  //     testingCodeToCopy.setAttribute('type', 'text');    // 不是 hidden 才能複製
  //     testingCodeToCopy.select()

  //     try {
  //       var successful = document.execCommand('copy');
  //       var msg = successful ? 'successful' : 'unsuccessful';
  //       alert('Testing code was copied ' + msg);
  //     } catch (err) {
  //       alert('Oops, unable to copy');
  //     }

  //     /* unselect the range */
  //     testingCodeToCopy.setAttribute('type', 'hidden');
  //     window.getSelection().removeAllRanges();
  //   },
  },
});
